<template>
	<router-view />
</template>

<style lang="scss">
* {
	padding: 0;
	margin: 0;
}

html,
body,
#app {
	width: 100%;
	height: 100%;
	margin-top: 0px;
}
body{
	margin: 0;
	padding: 0;
	border: 0;
}
</style>
